<template>
  <div>
    <b-row class="card">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-pen"></i> Filtros
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-row>
          <b-col lg="6">
            <b-form-group label="Inversor">
              <b-form-input v-model="filters.inverter"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="2">
            <b-form-group label="Potência">
              <b-form-input v-model="filters.potency"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="4" class="align-btn">
            <b-button variant="info" class="mr-2">Pesquisar</b-button>
            <b-button variant="danger" @click="resetInput">Limpar</b-button>
          </b-col>
        </b-row>
      </div>
    </b-row>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-charging-station"></i> Inversores Cadastrados
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-button variant="primary" :to="{ name: 'inverter-create' }">
          <i class="fas fa-plus"></i> Adicionar novo inversor
        </b-button>
      </div>
      <div class="w-100 p-3">
        <b-table
        id="tb-inverters"
        responsive
        bordered
        :current-page="currentPage"
        :per-page="perPage"
        :busy="load"
        :items="inverters"
        :fields="columsTableInverters">
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>
          <template v-slot:cell(actions)="data">
                <div>
                  <b-button
                    :to="{ name: 'inverter-edit', params: { id: data.item.id } }"
                    v-b-tooltip.hover
                    title="Editar Inversor"
                    size="sm"
                    class="mr-1"
                    variant="warning"
                  >
                      <i class="fas fa-pencil-alt"></i> Editar
                  </b-button>
                  <b-button
                    @click="confirmDelete(data.item.id)"
                    v-b-tooltip.hover
                    title="Excluir Inversor"
                    size="sm"
                    class="mr-1"
                    variant="danger"
                  >
                      <i class="fas fa-trash"></i> Excluir
                  </b-button>
                </div>
            </template>
        </b-table>
      </div>

      <div class="w-100 py-3" v-if="totalRows > 15">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
    </b-row>
  </div>
</template>

<script>
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import InverterService from '@/Services/InverterService';

export default {
  data() {
    return {
      columsTableInverters: InverterService.getFieldsTableInverters(),
      id_remove: null,
      load: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 15,
      inverters: [],
      filters: {
        potency: '',
        inverter: '',
      },
      payload: {
        description: '',
        number_in: '',
        power: '',
        overload: '',
        qtd_black_wire: '',
        qtd_red_wire: '',
        gauge: '',
        mc4: '',
        price: '',
      },
    };
  },
  mixins: [MessagesMixin, QuestionMixin],
  methods: {
    async getAll() {
      // const filters = { ...this.filters };

      try {
        const { data } = await InverterService.getList();
        this.totalRows = data.length;
        this.inverters = data.map((item) => ({
          id: item.id,
          description: item.description,
          number_in: item.number_in,
          power: item.power,
          overload: item.overload,
          qtd_black_wire: item.qtd_black_wire,
          qtd_red_wire: item.qtd_red_wire,
          gauge: item.gauge,
          mc4: item.mc4,
          price: this.$options.filters.currency(item.price),
        }));
      } catch (error) {
        console.log(error);
      }
      this.load = false;
    },
    resetInput() {
      this.filters.potency = '';
      this.filters.inverter = '';
    },
    confirmDelete(id) {
      this.id_remove = id;
      this.$toast.question(
        'Deseja realmente remover esse inversor ?',
        'Atenção',
        this.getQuestionObj(this.deleteInverter, '', 'Inversor removido'),
      );
    },
    async deleteInverter() {
      try {
        await InverterService.delete(this.id_remove);
        this.getAll();
        this.$toastr.s('Inversor excluído com sucesso!');
      } catch (error) {
        this.$toastr.e('Erro ao excluir inversor!');
      }
    },
  },
  created() {
    this.getAll();
  },
};
</script>
